export class BaseModel {

    /**
     * Assign values to model
     *
     * @param object
     */
    assign(object = {}) {
        Object.keys(object).forEach((property) => {
            // doing this because arrays will be copied by reference
            if (object[property] instanceof Array) {
                if (!(this[property] instanceof Array)) {
                    this[property] = [];
                }

                if (!this[property]) {
                    console.error(`Add the property ${property} to the model ${this.constructor.name}`);
                }

                this[property].splice(0, this[property].length, ...object[property]);
            } else if (object[property] instanceof Object && this[property] instanceof BaseModel) {
                this[property].assign(object[property]);
            } else {
                this[property] = object[property];
            }
        });

        return this;
    }

    /**
     * Compares objects for equality
     *
     * @param object
     *
     * @returns {boolean}
     */
    equals(object = {}) {
        return Object.keys(object).every((property) => {
            if (object[property] instanceof Array) {
                if (object[property].length !== this[property].length) {
                    return false;
                }

                return object[property].sort().toString() === this[property].sort().toString();
            }

            if (this[property] instanceof BaseModel) {
                return this[property].equals(object[property]);
            }

            if (typeof object[property] !== 'undefined' && object[property] !== null && object[property].length === 0) {
                object[property] = null;
            }

            if (typeof this[property] !== 'undefined' && this[property] !== null && this[property].length === 0) {
                if (typeof this[property] === 'string') {
                    object[property] = this[property];
                } else {
                    this[property] = null;
                }
            }

           let isEqual = object[property] === this[property];

            if (!isEqual) {
                // input 'number's don't work as they should, if the value is changed it will be treated as a string
                if (typeof object[property] === 'number') {
                    isEqual = object[property] === Number(this[property]);
                }

                if (!isEqual) {
                    console.warn(`Verify the property ${property} of the model ${this.constructor.name}`);
                }
            }

            return isEqual;
        });
    }

}
